<template>
    <div>
        <div class="row" v-if="MostrarMapa">
            <div class="col-lg-12">
                <Search 
                    :pIsDraggable="false" 
                    :pEstatusTrab="CheckTrabajador.EstatusTrab"
                    :pHomeOffice="CheckTrabajador.HomeOffice" 
                    :oLatLng="oLatLngSucursal" 
                    :pOLatLngTrab="oLatLng"
                    :pOLatLngHomeOffice="{Lat:parseFloat(CheckTrabajador.LatHome),Lng:parseFloat(CheckTrabajador.LngHome)}" 
                    :Input="false" 
                    :pOrigen="'checkin'"
                    :pProyecto="CheckTrabajador.ProyectoAsignado"
                    :pOLatLngProyecto="oLatLngProyecto"
                ></Search>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Ubicacion",
    props:["poBtnSave"],
    data() {
        return {
            Modal:true,
            FormName:'Ubicacion',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            CheckTrabajador:{
                IdCheckTrabajador:0,
                IdTrabajador:0,
                Latitud:0,
                Longitud:0,
                TipoCheck:'',
                FechaReg:'',
                FechaCheckOut:'',
                Imagen:'',
                Comentario:'',
                ComentarioCheckOut:'',
                LatitudOut:'',
                LongitudOut:'',
                Estatus:'',
                EstatusTrab:''
            },
            Tipo:'',
            Check:'',
            oLatLng:{},
            oLatLngSucursal:{},
            oLatLngProyecto:{},
            MostrarMapa:false
        }
    },
    methods: {
        Limpiar(){
            this.CheckTrabajador={
                IdCheckTrabajador:0,
                IdTrabajador:0,
                Latitud:0,
                Longitud:0,
                TipoCheck:'',
                FechaReg:'',
                FechaCheckOut:'',
                Imagen:'',
                Comentario:'',
                ComentarioCheckOut:'',
                LatitudOut:'',
                LongitudOut:'',
                Estatus:'',
                EstatusTrab:''
            }
            this.Check='';
            this.oLatLng={};
            this.oLatLngSucursal={};
            this.oLatLngProyecto={};
        },
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(CheckTrabajador,Check,Sucursal)=>{
            this.MostrarMapa=false;
            this.Limpiar();
            this.CheckTrabajador=CheckTrabajador;
            this.CheckTrabajador.EstatusTrab=CheckTrabajador.IdPerfil == 4 ? 'Tecnico' : 'Admin';
            this.Check=Check;
            if (this.Check=='In') {
                this.oLatLng.Lat=parseFloat(this.CheckTrabajador.Latitud);
                this.oLatLng.Lng=parseFloat(this.CheckTrabajador.Longitud);
            }
            else if(this.Check=='Site'){
                this.oLatLng.Lat=parseFloat(this.CheckTrabajador.LatitudSite);
                this.oLatLng.Lng=parseFloat(this.CheckTrabajador.LongitudSite);
            }
            else{
                this.oLatLng={
                    Lat:parseFloat(this.CheckTrabajador.LatitudOut),
                    Lng:parseFloat(this.CheckTrabajador.LongitudOut),
                }
            }
            this.oLatLngSucursal.Lat=parseFloat(Sucursal.LatSuc);
            this.oLatLngSucursal.Lng=parseFloat(Sucursal.LongSuc);
            if (CheckTrabajador.ProyectoAsignado==1) {
                this.oLatLngProyecto={
                    Lat:parseFloat(CheckTrabajador.LatitudProyecto),
                    Lng:parseFloat(CheckTrabajador.LongitudProyecto)
                }
            }
            setTimeout(()=>{
                this.MostrarMapa=true;
            },1000);
        });
    },
}
</script>

<style>

</style>
