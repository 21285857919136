<template>
    <div class="col-md-12 col-lg-6 mb-3">
        {{anios}}
		{{anioActual}}
        <b-overlay :show="isOverlay" rounded="sm" spinner-variant="primary" class="h-100">
            <div class="row">
                <div class="col-12">
                    <div class="card card-grafica h-100">
                        <div class="card-body">
                            <div class="filtro-grafic" id="grafica_007" v-show="isVisible">
                                <div class="row justify-content-start">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                                        <form class="form-inline">
											<label class="mr-2">Año</label>
											<select @change="GetData()" v-model="Anio" class="form-control form-control-sm mr-2">
												<option v-for="(item, index) in ListaAnios" :key="index" :value="item">
													{{ item }}
												</option>
											</select>
										</form>
                                        <button type="button" class="btn close abs_01" @click="Ocultar(false)">
											<i class="fal fa-times"></i>
										</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-10 col-sm-10 col-md-8 col-lg-8">
									<h6 class="title-grafic side-l">
										Facturación y Cobranza Anual
									</h6>
								</div>
                                <div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
									<button type="button" class="btn-fil-002" title="Filtros" @click="mostrarfiltro()">
										<i class="fas fa-filter"></i>
									</button>
								</div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<hr />
								</div>
                            </div>
                            <div id="apx-02">
								<apexchart width="100%" height ="190%" :options="options" :series="series" ></apexchart>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
export default {
    name: "FacturasYCuentasPlan",
    props:['pListaAnios','pAnioActual'],
    data() {
        return {
            ListaAnios: [],
            Anio:'',
            Mes:'',
			isVisible: false,
			isOverlay: true,
            options: {
                chart: {
                    type: 'bar',
                    height: 280,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '80%',
                        distributed: true,
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetX: 70,
                    formatter: (value) => {
                        return "$" + this.numberto(value)

                    },
                    style: {
                        fontSize: '13px',
                        colors: ['#000']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                tooltip: {
                    custom: function ({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w
                    }) {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            w.globals.labels[dataPointIndex] +
                            ": " + "$"+
                            series[seriesIndex][dataPointIndex].toFixed(1)+
                            "</span>" +
                            "</div>"
                        );
                    }
                },

                xaxis: {
                    categories: ['Plan','Cobrado', 'Facturado', 'Prefacturado'],
                    labels: {
                        show: true,
                        formatter: (value) => {
                            return "$" + this.numberto(value)

                        },
                        style: {
                            colors: ['#0F3F87'],
                            fontSize: '13px',
                            fontFamily: 'Barlow, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        }
                    },

                },
                colors: ['#1C8C78','#66BB6A','#1B17BB','#038ED9'],

                yaxis: [{
                    labels: {
                        style: {
                            colors: ['#0F3F87'],
                            fontSize: '13px',
                            fontFamily: 'Barlow, sans-serif',
                            fontWeight: 400,
                        }
                    }
                }],
            },
            series: [],
        }
    },
    methods: {
        Ocultar(){
            this.isVisible = false;
        },
        mostrarfiltro(){
            this.isVisible = true;
        },
        async GetData(){
            this.isOverlay = true;
            return new Promise((resolve,reject)=>{
                this.$http.get('finanzasFacturasYCuentasVeloc/get',{
                    params: {
			    		Anio: this.Anio,
                        Mes:this.Mes
			    	}
                }).then((res)=>{
                    const dataArray 	= res.data.data;
                    let newArray 		= [dataArray.plan,dataArray.cuentas,dataArray.facturas,dataArray.prefacturas];
                    this.series 		= [{
						data: newArray,
					}];
                    this.isOverlay = false;
			    	this.isCharged = true;
                    resolve(res.data);
                }).catch((e) => {
					this.isOverlay = false;
					reject(e.response.data)
				});
            })
        },
        numberto(num){
          	//value = value.toFixed(0);
		  	let fixed = 0;
          	if (num === null) { return null; } // terminate early
          	if (num === 0) { return '0'; } // terminate early
          	fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
          	var b = (num).toPrecision(2).split("e"), // get power
              	k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
              	c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
              	d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
              	e = d + ['', ' K', ' M', ' B', ' T'][k]; // append power
          	return e;
      	}
    },
    created() {
        if(this.pListaAnios !== undefined){
			this.ListaAnios = this.pListaAnios;
		}

		if(this.pAnioActual !== undefined) {
			this.Anio = this.pAnioActual
		}
    },
    computed: {
		anios(){
			if(this.pListaAnios !== undefined){
				this.ListaAnios = this.pListaAnios;
			}
		},

		anioActual(){
			if(this.pAnioActual !== undefined) {
				this.Anio = this.pAnioActual

			}
		}
	}
}
</script>

<style>

</style>