<template>
	<div>
		<Clist :isFactura="true" :regresar="false" :pShowBtnAdd="false"  :Nombre="'Servicios Por Facturar'"
		   @FiltrarC="Lista" :Filtro="Filtro" :isModal="EsModal" :oHead="Head" :pConfigLoad="ConfigLoad">

            <template slot="botonextra">
                <div>
                    <button @click="go_to_facturadas()" data-backdrop="static" type="button" class="btn btn-pink mb-2 mr-3">Servicios Facturados</button>
                </div>
                <div>
                    <button @click="go_to_aprobadas()" data-backdrop="static" type="button" class="btn btn-pink mb-2 mr-3">Prefacturas</button>
                </div>
                <div>
                    <button @click="go_to_anuladas()" data-backdrop="static" type="button" class="btn btn-pink mb-2 mr-3">Anuladas</button>
                </div>
            </template>
            <template slot="FacturaDashboard">
                <div class="row mt-2">
                    <div class="col-lg-12">
                        <div class="card card-01">
                            <div class="row">
                                <div class="col-lg-2">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Servicios Sin Facturar</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px" class="text-center text-danger bold mt-0 pt-0">{{ ContadoresLista.SinFactura }}</h1>
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="row justify-content-center">
                                        <div class="col-md-4 col-lg-3 m-0 p-0">
                                            <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                                <h4 class="text-info bold">Prefacturado Mes Actual</h4>
                                                <br>
                                                <h1 style="line-height:1.2;font-size:53px" class="text-center text-info bold mt-0 pt-0">${{ formateoMillon(Number(ContadoresLista.MontoPrefacturaMes)) }}</h1>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 m-0 p-0">
                                            <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                                <h4 class="text-info bold">Facturado Mes Actual</h4>
                                                <br>
                                                <h1 style="line-height:1.2;font-size:53px;color:#1B17BB" class="text-center bold mt-0 pt-0">${{ formateoMillon(Number(ContadoresLista.MontoEsteMes)) }}</h1>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 m-0 p-0">
                                            <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                                <h4 class="text-info bold">Cobrado Mes Actual</h4>
                                                <br>
                                                <h1 style="line-height:1.2;font-size:53px" class="text-center text-success bold mt-0 pt-0">${{ formateoMillon(Number(ContadoresLista.MontoCtaPorCobrarMes)) }}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row justify-content-center">
                                <div class="col-lg-2 m-0 p-0">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Servicios Sin Facturar</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px" class="text-center text-danger bold mt-0 pt-0">{{ ContadoresLista.SinFactura }}</h1>
                                    </div>
                                </div>
                                <div class="col-lg-2 m-0 p-0">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Facturas Rechazadas Total</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px" class="text-center text-info bold mt-0 pt-0">{{ ContadoresLista.FacturasRechazadas }}</h1>
                                    </div>
                                </div>
                                <div class="col-lg-2 m-0 p-0">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Facturas Anuladas Total</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px" class="text-center text-warning bold mt-0 pt-0">{{ ContadoresLista.FacturasAnuladas }}</h1>
                                    </div>
                                </div>
                                <div class="col-lg-2 m-0 p-0">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Facturadas Realizadas Mes Actual</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px" class="text-center text-success bold mt-0 pt-0">{{ ContadoresLista.FacturasEsteMes }}</h1>
                                    </div>
                                </div>
                                <div class="col-lg-2 m-0 p-0">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Prefacturado Mes Actual</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px" class="text-center text-info bold mt-0 pt-0">${{ formateoMillon(Number(ContadoresLista.MontoPrefacturaMes)) }}</h1>
                                    </div>
                                </div>
                                <div class="col-lg-2 m-0 p-0">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Facturado Mes Actual</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px;color:#1B17BB" class="text-center bold mt-0 pt-0">${{ formateoMillon(Number(ContadoresLista.MontoEsteMes)) }}</h1>
                                    </div>
                                </div>
                                <div class="col-lg-2 m-0 p-0">
                                    <div class="card card-01" style="border:2px #e6e4e4 solid;width:83%">
                                        <h4 class="text-info bold">Cobrado Mes Actual</h4>
                                        <br>
                                        <h1 style="line-height:1.2;font-size:53px" class="text-center text-success bold mt-0 pt-0">${{ formateoMillon(Number(ContadoresLista.MontoCtaPorCobrarMes)) }}</h1>
                                    </div>
                                </div>
                            </div> -->
                        </div>				
                    </div>
                </div>
            </template>
			<template slot="Filtros">
				<form class="form-inline">
					<v-date-picker mode='range' v-model="rangeDate" @input="ListaPrincipal" :min-date="MinimoFecha()"
								   :input-props='{
										class: "form-control form-control ml-2 mr-1",
										placeholder: "Selecciona un rango de fecha para buscar",
										readonly: true
									}'/>

				</form>
				<!-- <button @click="limpiarDate" type="button" class="btn btn-01 btn-fil" title="Limpiar Rango">
					<i class="fas fa-broom"></i>
				</button> -->

				<button class="btn btn-01 ml-2"  @click="FacturaLibre(IdFactura,Factura)" type="button" v-b-tooltip.hover title="Pre Factura Sin Folio" >Pre Factura Sin Folio
</button>
			</template>

            <template slot="header">
                   <tr>
					   <th>Folio</th>
					   <th><span>Fecha</span> 
                        <button class="btn btn-link" type="button" @click="CambiarOrdenFecha">
                            <i v-show="Filtro.OrderFecha=='DESC'" class="fa fa-arrow-down text-white"></i>
                            <i v-show="Filtro.OrderFecha=='ASC'" class="fa fa-arrow-up text-white"></i>
                        </button>
                        </th>
					   <th>Cliente</th>
					   <th>Propiedad</th>
					   <th v-if="TipoFactura=='1'">Servicio</th>
                       <th v-if="TipoFactura=='1'|| TipoFactura=='2'">Estatus</th>
					   <th class="text-center tw-2">Acciones</th>
                    </tr>
            </template>

             <template  slot="body">
                   <tr :class="lista.Facturado=='Rechazada' ? 'bg-danger':''"  v-for="(lista,index) in ListaServ" :key="index" >
                       <template v-if="TipoFactura==1">
                           <td v-if="lista.TipoFactura!=2"><b class="bold">{{lista.Folio }} </b></td>
                           <td v-if="lista.TipoFactura==2"><b class="bold"> </b></td>
                           <td v-if="lista.TipoFactura!=2" class="tw-2"><i class="fas fa-calendar-day"></i> <b class="bold">{{lista.FechaI2 }}</b> </td>
                           <td v-if="lista.TipoFactura==2" class="tw-2"><i class="fas fa-calendar-day"></i> <b class="bold">{{$getCleanDate(lista.Fecha_Or,false) }}</b> </td>
                           <td>{{Cortador(lista.ClienteP)}} </td>
                            <td >{{Cortador(lista.Sucursal)}} </td>
                            <td v-if="lista.TipoFactura==2">{{lista.TipoServicio }} </td>
                            <td v-if="lista.TipoFactura!=2">{{lista.TipoServ }} </td>
                            <td v-if="lista.Facturado === 'NO'"> <b>Guardada</b>  </td>
                            <td v-if="lista.Facturado == null "> <b>Sin Factura</b>  </td>
                            <td v-if="lista.Facturado === 'Rechazada'"> <b>Rechazada</b>  </td>
                            <td v-if="lista.Facturado === 'Anulada'"> <b>{{lista.Facturado }}</b>  </td>
                            <td v-if="lista.Facturado === 'SI'"> <b>Aprobada</b>  </td>
                       </template>
                       <template v-else>
                           <td><b class="bold">{{lista.FolioFactura }} </b></td>
                           <td><i class="fas fa-calendar-day"></i> <b class="bold">{{lista.FechaReg}}</b> </td>
                           <td>{{Cortador(lista.NombreCliente) }} </td>
						   <td >{{Cortador(lista.Sucursal)}} </td>


						   <td v-if="EstatusFact === 2 && lista.Facturado === 'NO'"> <b>Guardada</b>  </td>
						   <td v-if="EstatusFact === 3 && lista.Facturado === 'Rechazada'"> <b> {{lista.Facturado }}</b></td>
						   <td v-if="EstatusFact === 4 && lista.Facturado === 'Anulada'"> <b>{{lista.Facturado }}</b> </td>
                       </template>

                        <td class="text-left tw-2">
                            <button v-if="lista.TipoFactura != 2" @click="Go_To_FacturaForm(lista.IdServicio)" class="btn-icon pl-01 mr-1" v-b-tooltip.hover title="Crear Factura" >
                                <i class="fas fa-plus-square fa-fw-m"></i>
                            </button>
                             <button v-else @click="FacturaLibre(lista.IdFactura,Factura)" class="btn-icon pl-01 mr-1" v-b-tooltip.hover title="Crear Factura" >
                                <i class="fas fa-plus-square fa-fw-m"></i>
                            </button>
                            <button v-if="lista.IdFactura != null && lista.Facturado !== 'Anulada'" @click="DescargarPdf(lista.IdFactura)" class="btn-icon pl-01" v-b-tooltip.hover title="PDF Factura">
                                <i class="fas fa-file-pdf fa-fw-m"></i>
                            </button>
                            <button v-if="lista.IdFactura != null && lista.Facturado == 'Rechazada'" @click="OpenComenario(lista.IdFactura, lista.ComentarioCancel)" class="btn-icon pl-01 ml-1" v-b-tooltip.hover title="Ver Motivo de Rechazo" data-toggle="modal" data-target="#Comentario"  data-backdrop="static" data-keyboard="false">
                                <i class="far fa-comments-alt fa-fw-m"></i>
                            </button>

                        </td>
                   </tr>
				 <CSinRegistros :pContIF="ListaServ.length" :pColspan="[TipoFactura === '1' ? 7 : 7]" ></CSinRegistros>
            </template>

        </Clist>

        <Modal :NameModal="'Comentario'" :poBtnSave="oBtnSave3" :size="size2" :Nombre="'Observación'"  >
            <template slot="Form">
                <ComentFacAnulada :poBtnSave="oBtnSave3"></ComentFacAnulada>
            </template>
        </Modal>

</div>

</template>
<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import Form from '@/views/modulos/factura/Form.vue'
import FacturaLibre from '@/views/modulos/factura/FacturaLibre.vue'
import CSinRegistros from "../../../components/CSinRegistros";
import ComentFacAnulada from '@/views/modulos/factura/ComentFacAnulada.vue';
import moment from "moment";

export default {
    props:[''],
    name :'list',
    components :{
        Modal,
        Clist,
        Form,
        FacturaLibre,
		CSinRegistros,
        ComentFacAnulada
    },
    data() {
        return {
            EsModal:true,//indica si es modal o no
            size :"None",
            urlApi:"caja/get",
            ListaServ:[],
            ListaFactLibre:[],
            ListaAnuladas:[],
            ListaHeader:[],
            TotalPagina:2,
            NameList:'',
            Pag:0,
            Head:{
                Title:'Servicios para Facturar',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:true,
                isModal:false,
                isEmit:false,
                Url:'submenufact',
                ObjReturn:'',
            },
            AFacturar:'',
            FechaFacReal:'',
            Filtro:{
                Nombre:'',
                Placeholder:'Folio ...',
                TotalItem:0,
                Pagina:1,
                Entrada:20,
                OrderFecha:'DESC'
            },
            TipoFactura:1,
            IdFactura:0,
            Factura:1,
            isOverlay: true,

			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},

            oBtnSave3:{
                ShowSave:false,
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:'Comentario'
            },
            size2 :"modal-md",

            EstatusFact:1,
			rangeDate:null,
            ContadoresLista:{
                SinFactura:0,
                // FacturasRechazadas:0,
                // FacturasAnuladas:0,
                // FacturasEsteMes:0,
                MontoPrefacturaMes:0,
                MontoEsteMes:0,
                MontoCtaPorCobrarMes:0
            }
        }
    },
    methods: {
        async ListaPrincipal() {
			this.ConfigLoad.ShowLoader = true;
			let FechaInicial = '';
			let FechaFinal = '';
			if(this.rangeDate != null){
				FechaInicial 	= (this.rangeDate.start === null || this.rangeDate.start === '')  ? '' : moment(this.rangeDate.start).format('YYYY-MM-DD');
				FechaFinal 		= (this.rangeDate.end === null || this.rangeDate.end === '') ?  '' :  moment(this.rangeDate.end).format('YYYY-MM-DD');

			}

			if(this.TipoFactura === 1 && this.EstatusFact === 1) {

				await this.$http.get('factura/servxfact', {
                        params:{
							Nombre: this.Filtro.Nombre,
							Entrada: this.Filtro.Entrada,
							pag: this.Filtro.Pagina,
							RegEstatus: 'A',
							TipoFactura: this.TipoFactura,
                            OrderFecha:this.Filtro.OrderFecha,
							Fecha_I: FechaInicial,
							Fecha_F: FechaFinal
						}
				}).then( (res) => {

                    this.ListaServ			= res.data.data.servxfact;
                    this.Filtro.Entrada		= res.data.data.pagination.PageSize;
                    this.Filtro.TotalItem	= res.data.data.pagination.TotalItems;
					this.Filtro.Pagina 		= res.data.data.pagination.CurrentPage;

                }).catch((e) => {
					console.error(e);

                }).finally(()=>{
					this.ConfigLoad.ShowLoader = false;

                });

            }else if(this.TipoFactura === 1 && this.EstatusFact > 1)  {

					let estatus = '';
					switch (this.EstatusFact){
						case 2:
							estatus = 'NO';
							break;
						case 3:
							estatus = 'Rechazada';
							break;
						case 4:
							estatus = 'Anulada';
							break
					}

                    await  this.$http.get('factura/facturaxEstatusList', {
						params:{
							Nombre: this.Filtro.Nombre,
							Entrada: this.Filtro.Entrada,
							pag: this.Filtro.Pagina,
							Facturado: estatus,
							Fecha_I: FechaInicial,
							Fecha_F: FechaFinal
						}
					}).then( (res) => {
                            this.ListaServ			= res.data.data.facturas;
                            this.Filtro.Entrada		= res.data.data.pagination.PageSize;
                            this.Filtro.TotalItem	= res.data.data.pagination.TotalItems;
                            this.Filtro.Pagina 		= res.data.data.pagination.CurrentPage;

					}).catch((e) => {
						console.error(e);

					}).finally(()=>{
						this.ConfigLoad.ShowLoader = false;
					});

            }else if(this.TipoFactura === 2 && this.EstatusFact === 1)  {

               this.ListaServ=[];
			   this.ConfigLoad.ShowLoader = false;

            }else if(this.TipoFactura === 2 && this.EstatusFact > 1)  {

				let estatus = '';
				switch (this.EstatusFact){
					case 2:
						estatus = 'NO';
						break;
					case 3:
						estatus = 'Rechazada';
						break;
					case 4:
						estatus = 'Anulada';
						break
				}

				await  this.$http.get('factura/facturaLibreEstatusList', {
					params:{
						Nombre: this.Filtro.Nombre,
						Entrada: this.Filtro.Entrada,
						pag: this.Filtro.Pagina,
						TipoFactura: this.TipoFactura,
						Facturado: estatus,
						Fecha_I: FechaInicial,
						Fecha_F: FechaFinal
					}
				}).then( (res) => {
					this.ListaServ=res.data.data.facturaLibre;
					this.Filtro.Entrada=res.data.data.pagination.PageSize;
					this.Filtro.TotalItem=res.data.data.pagination.TotalItems;


				}).catch((e) => {
					console.error(e);

				}).finally(()=>{
					this.ConfigLoad.ShowLoader = false;
				});
            }


        },

        // NUEVA VERSION DE LA LISTA, SIN FILTROS
        async Lista(){
            this.ConfigLoad.ShowLoader = true;
			let FechaInicial = '';
			let FechaFinal = '';
            let AnioInicial=moment().subtract(1,'year').format('YYYY-MM-DD');
            let AnioFinal=moment().format('YYYY-MM-DD');
            console.log(AnioInicial);
            console.log(AnioFinal);
			if(this.rangeDate != null){
				FechaInicial 	= (this.rangeDate.start === null || this.rangeDate.start === '')  ? '' : moment(this.rangeDate.start).format('YYYY-MM-DD');
				FechaFinal 		= (this.rangeDate.end === null || this.rangeDate.end === '') ?  '' :  moment(this.rangeDate.end).format('YYYY-MM-DD');
			}
            await this.$http.get('factura/v2/get',{
                params:{
                    Nombre: this.Filtro.Nombre,
					Entrada: this.Filtro.Entrada,
					pag: this.Filtro.Pagina,
					Fecha_I: FechaInicial,
					Fecha_F: FechaFinal,
                    AnioInicial: AnioInicial,
                    AnioFinal: AnioFinal,
                    OrderFecha:this.Filtro.OrderFecha
                }
            })
            .then((res)=>{
                this.ListaServ=res.data.data.servxfact;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
				this.Filtro.TotalItem=res.data.data.pagination.TotalItems;

            })
            .finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            })
        },

        // TODOS LOS CONTADORES
        Contadores(){
            let MesActual=moment().format('MM-YYYY');
            let AnioInicial=moment().subtract(1,'year').format('YYYY-MM-DD');
            let AnioFinal=moment().format('YYYY-MM-DD');
            this.$http.get('facturacontadores/v2/get',{
                params:{
                    MesActual:MesActual,
                    AnioInicial:AnioInicial,
                    AnioFinal:AnioFinal,
                }
            }).then((res)=>{
                this.ContadoresLista.SinFactura=res.data.data.SinFacturar;
                this.ContadoresLista.MontoPrefacturaMes=res.data.data.MontoPrefacturaMes;
                this.ContadoresLista.MontoCtaPorCobrarMes=res.data.data.MontoCtaPorCobrarMes;
                // this.ContadoresLista.FacturasRechazadas=res.data.data.FacturasRechazadas;
                // this.ContadoresLista.FacturasAnuladas=res.data.data.FacturasAnuladas;
                // this.ContadoresLista.FacturasEsteMes=res.data.data.FacturasEsteMes;
                this.ContadoresLista.MontoEsteMes=res.data.data.MontoEsteMes;
            })
        },

        FacturaLibre(Id,Factura){
             this.$router.push({name:'FacturaLibre',params:{Id:parseInt(Id),Factura:parseInt(Factura)}})
        },
        Go_To_FacturaForm(Id)
        {
            this.$router.push({name:'formfact',params:{Id:parseInt(Id)}})
        },

        go_to_facturadas(){
            this.$router.push({name:'ListFacturas',params:{Tipo:4}})
        },
        go_to_aprobadas(){
            this.$router.push({name:'ListFacturas',params:{Tipo:2}})
        },

        go_to_anuladas(){
            this.$router.push({name:'ListFacturas',params:{Tipo:3}})
        },

        Cortador(lista){
            if (lista!='' && lista!=undefined) {
                return lista.substr(0, 20);
            }
        },


		DescargarPdf(IdFactura) {
            if (IdFactura !='' || IdFactura !=null)
            {
                   this.$http.get(
                'reporte/factura',
                {
                responseType: 'blob',
                params :{
                        IdFactura:IdFactura,
                    }
                }
            ).then( (response) => {

                    let pdfContent = response.data;
                     let file = new Blob([pdfContent], { type: 'application/pdf' });
                    let fileUrl = URL.createObjectURL(file);

                    window.open(fileUrl);

            });
            }
        },
        OpenComenario(Id,Comentario){
            this.bus.$emit('AbrirCom',Id,Comentario)
        },
        MinimoFecha(){
            let Fecha=moment().add(1,'days').subtract(1,'year').format('YYYY-MM-DD');
            Fecha=new Date(Fecha);
            return Fecha;
        },
		limpiarDate() {
			this.rangeDate = null;
			this.ListaPrincipal();
		},
        formateoMillon(numero){
            let fixed = 0;
			let e=0;
			if (numero === null) {
				return null;
			} // terminate early
			if (numero === 0) {
				return "0";
			} // terminate early
			fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
			var b = numero.toPrecision(2).split("e"), // get power
				k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 6), // floor at decimals, ceiling at trillions
				c =
					k < 1
						? numero.toFixed(0 + fixed)
						: (numero / Math.pow(10, k * 6)).toFixed(1 + fixed), // divide by power
				d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
				if (k==0) {
					// e=new Intl.NumberFormat('en-US',{style:'currency',currency:'USD',roundingPriority:'morePrecision'}).format(d);
					e=d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				}
				else{
					e = d + ["", " M", " B", " T"][k]; // append power
				}
			return e;
        },
        CambiarOrdenFecha(){
            if (this.Filtro.OrderFecha=='DESC') {
                this.Filtro.OrderFecha='ASC'
            }
            else{
                this.Filtro.OrderFecha='DESC'
            }
            if(this.rangeDate != null){
                this.ListaPrincipal();
            }
            else{
                this.Lista();
            }
        }

    },

    created() {
        this.bus.$off('List');
        this.bus.$off('Regresar');
        // this.ListaPrincipal();
        this.Lista();

		this.bus.$on('List',()=> {
            // this.ListaPrincipal();
            this.Lista();
        });

         this.bus.$on('Regresar',()=>
        {
            this.$router.push({name:'submenufact'});
        });
        this.Contadores();
    },

	mounted() {
		this.rangeDate = null
	}
}
</script>
