<template>
    <div>

    <Clist :regresar="true" :pShowBtnAdd="false" :Nombre="Head.Title"
		   @FiltrarC="ListaPrincipal" :Filtro="Filtro"  :isModal="EsModal" :oHead="Head"
		   :pConfigLoad="ConfigLoad">

        <template slot="botonextra">
                <div>
                    <button @click="go_to_porfacturar()" data-backdrop="static" type="button" class="btn btn-pink mb-2 mr-3">Servicios Por Facturar</button>
                </div>
                <div>
                    <button v-if="Tipo2!=2" @click="go_to_aprobadas()" data-backdrop="static" type="button" class="btn btn-pink mb-2 mr-3">Prefacturas</button>
                </div>
                <div>
                    <button v-if="Tipo2!=3 && Tipo2!=2" @click="go_to_anuladas()" data-backdrop="static" type="button" class="btn btn-pink mb-2 mr-3">Anuladas</button>
                </div>
                <div>
                    <button v-if="Tipo2==2" @click="go_to_facturadas()" data-backdrop="static" type="button" class="btn btn-pink mb-2 mr-3">Servicios Facturados</button>
                </div>
        </template>
        <template slot="Filtros">
			<form class="form-inline">
				<v-date-picker mode='range' v-model='rangeDate' @input="ListaPrincipal"
							   :input-props='{
										class: "form-control form-control ml-2 mr-2",
										placeholder: "Selecciona un rango de fecha para buscar",
										readonly: true
									}'/>
				<!-- <button @click="limpiarDate" type="button" class="btn btn-01 btn-fil" title="Limpiar Rango">
					<i class="fas fa-broom"></i>
				</button> -->
                <label v-if="Tipo2==4" class="mr-1">Tipo de Servicio</label>
                <select @change="ListaSubtipo()" v-model="IdConfigS" class="form-control mr-2" v-if="Tipo2==4">
                    <option :value="''">Todos</option>
                    <option v-for="(item, index) in ListaServicios" :key="index" :value="item.IdConfigS">{{item.Nombre}}</option>
                </select>

                <label v-if="Tipo2==4" class="mr-1">SubTipo de Servicio</label>
                <select @change="Lista()" v-model="IdTipoSer" class="form-control" v-if="Tipo2==4">
                    <option :value="''">Todos</option>
                    <option v-for="(item, index) in ListaTipoServicio" :key="index" :value="item.IdTipoSer">{{item.Concepto}}</option>
                </select>
			</form>
        </template>
            <template slot="header">
                   <tr >
                        <th>Cliente</th>
                        <th>Propiedad</th> 
                        <th>Servicio</th>
                        <th>Prefactura</th>
                        <th v-if="Tipo2==4">Facturado</th>
                        <th v-if="Tipo2==4">Pagado</th>
                        <th v-if="Tipo2==3">Fecha de Anulación</th>
                        <th style="text-align:center;"  >No. Prefactura</th>
                        <th style="text-align:center;">Folio</th>

                        <th style="text-align:center;"  v-if="TipoFiltro!=1 || Tipo2==4">Folio Factura Real</th>
                        <th>Monto</th>
                        <th class="text-center tw-2">Acciones</th>
                    </tr>

            </template>
             <template slot="body">
                   <tr v-for="(lista,index) in ListaFacturas" :key="index" >
                       <td>{{lista.NombreCliente.substr(0, 22) }} </td>
                       <td>{{lista.Sucursal.substr(0, 25) }} </td>
                       <td class="tw-2"><i class="fas fa-calendar-day"></i> {{lista.FechaI2 }} </td>
                       <td class="tw-2"><i class="fas fa-calendar-day"></i> {{lista.FechaRegFormat }} </td>
                       <!--AQUÍ-->
                       <td class="tw-2" v-if="Tipo2==4"><i class="fas fa-calendar-day"></i> {{lista.FechaFacRealF }} </td>
                       <td class="tw-2" v-if="Tipo2==4"><i class="fas fa-calendar-day"></i> {{lista.FechaRealCobro!=null ? $getCleanDate(lista.FechaRealCobro,false) : 'No Pagado' }} </td>
                       <td class="tw-2" v-if="Tipo2==3 "><i class="fas fa-calendar-day"></i> {{lista.FechaAnuladoF }} </td>
                       <td style="text-align:center;" ><b >{{lista.FolioFactura }}</b> </td>
                       <td style="text-align:center;"><b>{{lista.FolioServ }}</b> </td>
                       <td  style="text-align:center;" v-if="TipoFiltro!=1 || Tipo2==4"><b>{{lista.FolioFactReal }}</b> </td>
                       <td v-if="parseInt(TipoFiltro) === 1">${{Number( lista.Total).toLocaleString("es-MX")   }} </td>
                       <td v-else-if="parseInt(TipoFiltro) === 2">${{Number(lista.Monto).toLocaleString("es-MX") }} </td>


                        <td class="text-left tw-2">
                            <template v-if="Tipo2==1">
                                <div>
                                    <button type="button" @click="Autorizar(lista.IdFactura)" class="btn-icon pl-01 mr-1" v-b-tooltip.hover title="Autorizar Factura">
                                        <i class="fas fa-file-check fa-fw-m"></i>
                                    </button>
                                    <button class="btn-icon pl-01 mr-1"  @click="OpenCancel(index)"  data-toggle="modal" data-target="#ModalForm4"  data-backdrop="static" v-b-tooltip.hover title="Cancelar Factura" >
                                        <i class="fas fa-file-times fa-fw-m"></i>
                                    </button>
                                    <button @click="DescargarPdf(lista.IdFactura)" class="btn-icon pl-01" v-b-tooltip.hover title="PDF Prefactura">
                                        <i class="fas fa-file-pdf fa-fw-m"></i>
                                    </button>
                                </div>
                            </template>
                            <template v-if="Tipo2==2 || Tipo2==3 || Tipo2==4">
                              <div>
                               <button v-b-tooltip.hover title="Editar" class="btn-icon pl-01 mr-1" v-if="Tipo2==2 || Tipo2==4"  @click="OpenFactura(index,lista.IdFactura,lista.DiasCredito,lista.Observacion,lista.FechaFacReal,lista.IdServicio)" type="button" data-toggle="modal" data-target="#Factura"  data-backdrop="static"  >
                                    <i class="fas fa-pencil-alt fa-fw-m"></i>
                                </button>
                                <button @click="DescargarPdf(lista.IdFactura)" class="btn-icon pl-01 mr-1" type="button" v-b-tooltip.hover title="Prefactura"><i class="fas fa-file-pdf fa-fw-m"></i></button>
                                <button v-show="lista.ArchivoFactura!=null && lista.ArchivoFactura !=''" @click="DescargarPdfReal(lista.ArchivoFactura)" class="btn-icon pl-01 mr-1" type="button" v-b-tooltip.hover title="Factura" v-if="Tipo2==2 || Tipo2==4" ><i class="fas fa-file-pdf fa-fw-m"></i></button>
                                <!---COMENTARIO DE FACTURAS ANULADAS-->
                                <button v-if=" Tipo2==3"  v-b-tooltip.hover.lefttop title="Comentario"  class="btn-icon pl-01 mr-1" @click="OpenComenario(lista.IdFactura, lista.ComentarioAnulada)"  data-toggle="modal" data-target="#Comentario"  data-backdrop="static" data-keyboard="false" type="button" >
                                    <i class="far fa-comments-alt" aria-hidden="true"></i>
                                </button>
                              </div>
                          </template>
                        </td>
                   </tr>
				 <CSinRegistros :pContIF="ListaFacturas.length" :pColspan="rowSinregistro" ></CSinRegistros>

            </template>
        </Clist>
    <Modal :NameModal="'ModalForm4'" :Showbutton="false"   :size="'None'" :Nombre="'Cancelación de Factura'" >
      <template slot="Form">
         <fieldset class="sin">
              <div class="row mt-2">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h4 class="titulo-04">Datos de la Factura</h4>
                </div>
              </div>

              <div class="form-group form-row mt-2">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <label>Folio de la Factura</label>
                  <input type="text" readonly v-model="factura.Folio" class="form-control" placeholder="Folio">
                </div>
                   <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <label>Motivo de Cancelación</label>
                  <textarea class="form-control" v-model="factura.Motivo" name="" id="" cols="6" rows="3"></textarea>
                  <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Motivo" :Mensaje="errorvalidacion.Motivo[0]"></Cvalidation></label>
                </div>
              </div>
        </fieldset>
        <div >
            <div class="modal-footer modal-footer-form ">
                <button :disabled="loading" @click="closeCancel" type="button" class="btn btn-04 ban mr-2">Cancelar</button>
                <button :disabled="loading" @click="Cancelar" class="btn btn-01 save">  <i v-show="loading" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> {{txtSave}}</button>
            </div>
        </div>


        <!------Comentando nada más para subir---->

      </template>
    </Modal>

         <Modal :NameModal="'Factura'"   :poBtnSave="oBtnSave" :size="'modal-lg'" :pEmitSeccion="EmitSeccion" :Nombre="'Editar Información'" >
      <template slot="Form">
         <FormChange :pOrigen="'facturas'" @Listar="Lista" :poBtnSave="oBtnSave" :factura="factura">
         </FormChange>

      </template>
    </Modal>

    <Modal :NameModal="'Comentario'" :poBtnSave="oBtnSave3" :size="size2" :Nombre="'Observación'"  >
        <template slot="Form">
            <ComentFacAnulada :poBtnSave="oBtnSave3"></ComentFacAnulada>
        </template>
    </Modal>

</div>
</template>
<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import Cbtnaccion from '@/components/Cbtnaccion.vue';

import Form from '@/views/modulos/factura/Form.vue'
import FormChange from '@/views/modulos/factura/FormChange.vue'
import ComentFacAnulada from '@/views/modulos/factura/ComentFacAnulada.vue';
import CSinRegistros from "../../../components/CSinRegistros";
import moment from "moment";

export default {
    props:['Tipo'],
    name :'list',
    components :{
        Modal,
        Clist,
        Cbtnaccion,
        Form,
        FormChange,
        ComentFacAnulada,
		CSinRegistros

    },
    data() {
        return {
            EmitSeccion:'Factura',
            FormName:'formfact',//Por si no es modal y queremos ir a una vista declarada en el router
            EsModal:true,//indica si es modal o no
            size :"None",
            size2 :"modal-md",
            urlApi:"caja/get",
            ListaFacturas:[],
            ListFechas:[],
            ListaHeader:[],
            NameList:'',
              Head:{
                Title:'Autorizar Prefactura',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:true,
                isModal:false,
                isEmit:false,
                Url:'submenufact',
                ObjReturn:'',
             },
            AFacturar:'',
            FechaFacReal:'',
            Facturado:'',
            IdServicio:0,
            TipoFiltro:1,
            RegEstatus:'A',
            Anulado:'',
            IdConfigS:'',
            IdTipoSer:'',
            factura: {
                IdFactura:0,
                IdServicio:0,
                Motivo:'',
                Folio:'',
                Monto:'',
                RegEstatus:'A',
                FolioFactReal:'',
                FechaFacReal:'',
                File:'',
                FilePrevious:'',
                ComentarioAnulada:'',
                DiasCredito:'',
                FolioFactura:'',
                Observacion:'',
                FechaCobro:'',
                FechaAnulado:'',
                Facturado:'',
                ComentarioCancel:'',
            },

            txtSave:'Guardar',
            loading:false,
            Disablebtn:false,
             Filtro:{
                Nombre:'',
                Placeholder:'Cliente/Sucursal ...',
                 TotalItem:0,
                Pagina:1,
                Entrada:20
            },
            Tipo2:'',
            errorvalidacion:[],
             oBtnSave:{//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:'Factura',
                EmitSeccion:'Factura'
            },
             oBtnSave3:{//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:'Comentario'
            },
            RutaFileOrg: '',
             TipoFactura:'1',
			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
			rowSinregistro: 0,
			rangeDate: null,
            ListaServicios:[],
            ListaTipoServicio:[]
        }
    },
    methods: {
        GetListaServicios() {
			this.$http.get('baseactual/get',{
				params:{
					RegEstatus:'A',
					Entrada:''
				}
			}).then( (res) => {
				this.ListaServicios	= res.data.data.lista;
				this.ListaSubtipo();
			});
		},
        async ListaSubtipo() {
			if (this.IdConfigS>0)
			{
				await this.$http.get('tiposervicio/get',{
					params:{
						RegEstatus:'A',
						IdConfigS:this.IdConfigS,
						IdTipoServ:this.IdTipoSer,
						Entrada:''
					}
				}).then( (res) => {
					this.ListaTipoServicio =res.data.data.tiposervicio;
					this.IdTipoSer="";
                    this.Lista();
					// this.get_listdata();
				});
			}
            else{
                this.ListaTipoServicio=[];
                this.Lista();
            }
		},

        OpenCancel(index)
        {
            this.factura.IdFactura=this.ListaFacturas[index].IdFactura;
            this.factura.IdServicio=this.ListaFacturas[index].IdServicio;
            this.factura.Folio=this.ListaFacturas[index].FolioFactura;
            this.factura.Monto=this.ListaFacturas[index].Monto;
            this.errorvalidacion=[];
        },
        closeCancel()
        {
            $('#ModalForm4').modal('hide');
        },
        Cancelar()
        {
            this.factura.Motivo = this.factura.Motivo.trim();
            this.loading=true;
                this.$http.post(
                        'factura/Cancelar',
                        this.factura
                    ).then( (res) => {
                         $('#ModalForm4').modal('hide');
                        this.loading=false;
                        this.$toast.success('Información Guardada');
                        this.Limpiar();
                        this.Lista();
                    }).catch( err => {
                        this.loading=false;
                        this.errorvalidacion=err.response.data.message.errores;
                this.$toast.error('No se pudo actulizar la información');

                });

        },
        Autorizar(Id)
        {
            this.factura.IdFactura=Id;

                    this.$swal({
                title: 'Esta seguro que desea autorizar esta prefactura?',
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then((result) => {
                if(result.value) {

                      this.$http.post(
                        'factura/Autorizar',
                        this.factura
                    ).then( (res) => {
                        this.$toast.success('Información Guardada');
                        this.Lista();
                        this.Limpiar();
                    }).catch( err => {
                    this.$toast.error('No se pudo actulizar la información');

                    });

                    }
                });
        },
        async Lista() {
			this.ConfigLoad.ShowLoader = true;
			let FechaInicial = '';
			let FechaFinal = '';

			if(this.rangeDate != null) {
				FechaInicial 	= this.rangeDate.start === '' ? '' : moment(this.rangeDate.start).format('YYYY-MM-DD');
				FechaFinal 		= this.rangeDate.end === '' ? '' : moment(this.rangeDate.end).format('YYYY-MM-DD');
			}

            await this.$http.get('facturaautorizada/v2/get', {
				params:{
					AFacturar:this.AFacturar,
					Facturado:this.Facturado,
					FechaFacReal:this.FechaFacReal,
					TipoFiltro:this.TipoFiltro,
					Nombre:this.Filtro.Nombre,
					Entrada:this.Filtro.Entrada,
                    TipoListado:this.Tipo2,
					pag:this.Filtro.Pagina,
					RegEstatus:this.RegEstatus,
					Anulado:this.Anulado,
					Fecha_I: FechaInicial,
					Fecha_F: FechaFinal,
                    IdConfigS:this.IdConfigS,
                    IdTipoSer:this.IdTipoSer
				}
			}).then((res) => {
                this.ListaFacturas		= res.data.data.prefacturas;
                this.Filtro.Entrada		= res.data.data.pagination.PageSize;
                this.Filtro.TotalItem	= res.data.data.pagination.TotalItems;
				this.Filtro.Pagina 		= res.data.data.pagination.CurrentPage;
                this.RutaFileOrg 		= res.data.RutaFileOrg;

            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});

        },

        ListaFactLibreAutorizada() {
			this.ConfigLoad.ShowLoader = true;
			let FechaInicial = '';
			let FechaFinal = '';
			if(this.rangeDate != null) {
				FechaInicial = this.rangeDate.start === '' ? '' : moment(this.rangeDate.start).format('YYYY-MM-DD');
				FechaFinal = this.rangeDate.end === '' ? '' : moment(this.rangeDate.end).format('YYYY-MM-DD');
			}

            this.$http.get('factura/facturaLibreAutorize/get', {
				params:{
					AFacturar:this.AFacturar,
					Facturado:this.Facturado,
					FechaFacReal:this.FechaFacReal,
					TipoFiltro:this.TipoFiltro,
					Nombre:this.Filtro.Nombre,
					Entrada:this.Filtro.Entrada,
					pag:this.Filtro.Pagina,
					RegEstatus:this.RegEstatus,
					TipoFactura:this.TipoFactura,
					Fecha_I: FechaInicial,
					Fecha_F: FechaFinal

				}
			}).then((res) => {
                this.ListaFacturas		= res.data.data.prefacturaslibreAutorizada;
                this.Filtro.Entrada		= res.data.data.pagination.PageSize;
                this.Filtro.TotalItem	= res.data.data.pagination.TotalItems;
				this.Filtro.Pagina 		= res.data.data.pagination.CurrentPage;
                this.RutaFileOrg 		= res.data.RutaFileOrg;

            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});
        },

        ListaPrincipal(){
            if(this.TipoFactura==='1'){
                this.Lista();
            }else if(this.TipoFactura==='2'){
                this.ListaFactLibreAutorizada();
            }
        },
        Limpiar() {
			this.factura.IdFactura=0;
			this.factura.IdServicio=0;
			this.factura.Motivo='';
			this.factura.Folio='';
			this.factura.Folio='';
			this.factura.Monto='';
			this.factura.RegEstatus='A';
			this.factura.FolioFactReal='';
			this.factura.FechaFacReal='';
			this.factura. File='';
			this.factura.FilePrevious='';
			this.factura.DiasCredito='';
			this.factura.Observacion='';
			this.factura.FechaCobro='';
			this.factura.FechaAnulado='';
        },
        OpenFactura(index, IdFactura,DiasCredito,Observacion,FechaFacReal,IdServicio)
        {
            this.Limpiar();

            this.factura.IdFactura=this.ListaFacturas[index].IdFactura;
            this.factura.IdServicio=this.ListaFacturas[index].IdServicio;
            this.factura.RegEstatus=this.ListaFacturas[index].RegEstatus;
            this.factura.Monto=this.ListaFacturas[index].Monto;
            this.factura.FolioFactReal=this.ListaFacturas[index].FolioFactReal;
            let formatedDate = this.ListaFacturas[index].FechaFacReal.replace(/-/g,'\/')
            this.factura.FechaFacReal = new Date(formatedDate);
            this.factura.FilePrevious=this.ListaFacturas[index].ArchivoFactura;
            this.factura.FolioFactura=this.ListaFacturas[index].FolioFactura;
            this.factura.DiasCredito=this.ListaFacturas[index].DiasCredito;
            if (Observacion=="null") {
                this.factura.Observacion="";
            }
            else{
                this.factura.Observacion=this.ListaFacturas[index].Observacion;
            }
            this.factura.FechaCobro=this.ListaFacturas[index].FechaCobro;
            this.factura.FechaAnulado=this.ListaFacturas[index].FechaAnulado;
            this.factura.IdClienteS=this.ListaFacturas[index].IdClienteS;
            this.factura.IdCliente=this.ListaFacturas[index].IdCliente;
            this.factura.IdServicio=this.ListaFacturas[index].IdServicio;
            this.factura.IdContrato=this.ListaFacturas[index].IdContrato;
            this.factura.Total=this.ListaFacturas[index].Total;
            this.factura.TipoFactura=this.ListaFacturas[index].TipoFactura;
            this.factura.IdConfigS=this.ListaFacturas[index].IdConfigS;
            this.factura.IdTipoSer=this.ListaFacturas[index].IdTipoSer;
            if (this.ListaFacturas[index].Facturado=="SI") {
                this.factura.isFacturado="NO"
                this.factura.Facturado="Facturada";
            }
            else{
                this.factura.isFacturado="SI"
                this.factura.Facturado=this.ListaFacturas[index].Facturado;
            }
            this.bus.$emit('EditarCance',this.oBtnSave,IdFactura,DiasCredito,Observacion,FechaFacReal,IdServicio);
            this.bus.$emit('Limpiar');

        },
         DescargarPdf(IdFactura)
        {
            if (IdFactura !='' || IdFactura !=null)
            {
                   this.$http.get(
                'reporte/factura',
                {
                responseType: 'blob',
                params :{
                        IdFactura:IdFactura,
                    }
                }
            ).then( (response) => {

                    let pdfContent = response.data;
                     let file = new Blob([pdfContent], { type: 'application/pdf' });
                    let fileUrl = URL.createObjectURL(file);

                    window.open(fileUrl);


            });
            }
        },

        DescargarPdfReal(nombre)
        {

            let pdfWindow = window.open(this.RutaFileOrg+nombre);
            pdfWindow.document.write("<iframe width='100%' height='100%' src='" + this.RutaFileOrg+nombre +"'></iframe>");
        },

        OpenComenario(Id,Comentario){
            this.bus.$emit('AbrirCom',Id,Comentario)
        },

		limpiarDate() {
			this.rangeDate = null;
			this.ListaPrincipal();
		},
        go_to_aprobadas(){
            this.$router.push({name:'ListFacturas',params:{Tipo:2},query:{Factura:"Aprobada"}})
        },

        go_to_anuladas(){
            this.$router.push({name:'ListFacturas',params:{Tipo:3},query:{Factura:"Anulada"}})
        },
        go_to_porfacturar(){
            this.$router.push({name:'listaserfac'})
        },
        go_to_facturadas(){
            this.$router.push({name:'ListFacturas',params:{Tipo:4},query:{Factura:"Facturada"}})
        },

    },
    created() {
		if (this.Tipo!=undefined) {
			sessionStorage.setItem('IdSaved',JSON.stringify(this.Tipo));
        }
        this.Tipo2 = sessionStorage.getItem('IdSaved');
        this.bus.$off('Regresar');
            if (this.Tipo2==1)
            {
                this.AFacturar='NO';
                this.Facturado='SI';
				this.rowSinregistro = 8;

            }
             if (this.Tipo2==2)
            {
                // this.RegEstatus='A';
                 this.AFacturar='SI';
                this.FechaFacReal='0000-00-00';
                this.Facturado='SI';
                this.RegEstatus='A';
                this.Head.Title='Aprobadas a Facturar';
				this.rowSinregistro = 9;
            }
            if (this.Tipo2==3)
            {
                this.Head.Title='Facturas Anuladas';
                this.RegEstatus='A';
                this.Anulado='SI';
                this.TipoFiltro=2;
				this.rowSinregistro = 10;
            }
            if (this.Tipo2==4)
            {
                this.TipoFiltro=2;
                this.Head.Title='Servicios Facturados';
                this.RegEstatus='A';
                this.Facturado='Facturada';
                this.AFacturar='SI';
                this.rowSinregistro = 10;
            }

            this.Lista();


         this.bus.$on('Regresar',()=>
        {
            this.$router.push({name:'listaserfac'});

        });
        this.GetListaServicios();


    },
	mounted() {
		this.rangeDate = null;
	},
}
</script>
