<template>
    <div>
		<input type="hidden" :value="Validate">
		<div class="row mt-2">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12">
				<h4 class="titulo-04">DATOS DEL CLIENTE</h4>
				<hr>
			</div>
		</div>

        <div class="form-group form-row mt-2">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6" >
                <label>Nombre del Cliente *</label>
                <input type="text" readonly v-model="localService.Client"  class="form-control form-control-sm" placeholder="Nombre Cliente">
				<Cvalidation v-if="this.localErrorvalidacion.Cliente" :Mensaje="localErrorvalidacion.Cliente[0]"/>
 
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <div class="form-inline">
                    <button @click="ListarCli" :disabled="pServicios.IdServicio>0"  data-toggle="modal" data-target="#ModalForm3"  data-backdrop="static" type="button" class="btn btn-01 search mt-3b">Buscar</button>
                </div>
            </div>
        </div>

		<div class="form-group form-row mt-2">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6" >
                <label>Nombre de la Propiedad *</label>
                <input type="text" readonly v-model="localService.Sucursal"  class="form-control form-control-sm" placeholder="Nombre Propiedad">
				<Cvalidation v-if="this.localErrorvalidacion.Cliente" :Mensaje="localErrorvalidacion.Cliente[0]"/>

            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
               
            </div>
        </div>

        <div class="form-group form-row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6" >
                <label>Dirección</label>
                <input type="text" readonly  v-model="localService.Direccion"  class="form-control form-control-sm" placeholder="Dirección Conocida">
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <label>Distancia Km</label>
                <vue-numeric :minus="false" @blur="CalcularDatos" class="form-control form-control-sm"  currency="" separator="," :precision="0" v-model.number="localService.Distancia" />
				<Cvalidation v-if="this.localErrorvalidacion.Distancia" :Mensaje="localErrorvalidacion.Distancia[0]"/>
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <label>Velocidad Km/hr</label>
                <vue-numeric   :minus="false" @input="CalcularDatos" class="form-control form-control-sm"  currency="" separator="," :precision="0" v-model.number="localService.Velocidad" />
				<Cvalidation v-if="this.localErrorvalidacion.Velocidad" :Mensaje="localErrorvalidacion.Velocidad[0]"/>
            </div>
        </div>

        <div class="form-group form-row" >
            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <label>Contacto</label>
                <input type="text" readonly  v-model="localClientesuc.ContactoS" class="form-control form-control-sm" placeholder="Nombre del Contacto">
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <label>No. de Contrato *</label>
                <select v-model="localService.IdContrato" @change="updatedata()" class="form-control form-control-sm">
                    <option value="">Seleccione Un Numero de Contrato</option>
                    <option value="0">Sin Número de Contrato</option>
					<option v-for="(item,index) in localConsultado.ListaNumc" :key="index" :value="item.IdContrato">
                        {{item.NumeroC}}
                    </option>
                </select>
				<Cvalidation v-if="this.localErrorvalidacion.IdContrato" :Mensaje="localErrorvalidacion.IdContrato[0]"/>
            </div>

        </div>

        <div class="form-group form-row">
			<!-- <vue-tags-input v-model="etiqueta" :tags="arraylista.lista" 
					@tags-changed="newTags => arraylista.lista = newTags" 
					@input="updatedata()" placeholder="Para" style="max-width: none;"/> -->

            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <label>Correo Electrónico</label>
                <vue-tags-input v-model="tag" :tags="localService.Para" 
					@tags-changed="newTags => localService.Para = newTags" 
					@input="updatedata()" placeholder="Para" style="max-width: none;"/>
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <label>Teléfono</label>
                <input type="text" readonly  v-model="localClientesuc.Telefono" class="form-control form-control-sm" placeholder="">
            </div>

			<div class="col-12 col-sm-12 col-md-2 col-lg-2">
				<div class="float_checkbox mt-3b">
					<label class="check_box">
						<input v-model="localService.Enviar" type="checkbox" value="true" @change="updatedata()">
						<span class="checkmark" style="border-color: #BDBDBD;"></span>
					</label>
					<span class="pl-4 ml-1">Enviar Correo</span>
				</div>

			</div>
        </div>
    </div>
</template>
<script>
import Cvalidation from '@/components/Cvalidation.vue'

export default {
    name:'Cliente',
    props:['pServicios','errorvalidacion','oclientesuc','Consultado'],
    components:{
      Cvalidation
    },
    data() {
       	return {
			tag:'', // SE UTILIZA LOCALMENTE PARA EL VUE-TAGS, NO REQUIERE DE SER RECIBIDO POR PROPS
		  
			localService: {
				IdServicio:0,
				IdContrato:'',

				Client: '', 
                Sucursal: '', 

				Direccion: '',
				Distancia: '',
				Velocidad: '',
				Para:[],
				Enviar:false,
				BurdenTotal:0,
				ManoObraT:0,
				CostoV:0,
			},

		   localErrorvalidacion:[],

		   localClientesuc:{
			   ContactoS:'',
			   Telefono:''
		   },

		   localConsultado: [],
		   TimeOut: null,

       	}
    },
    methods: {
        ListarCli() {
			this.$emit('Listar');
        },

        async CalcularDatos() {
			if (parseFloat(this.localService.IdServicio)>0) {
				clearTimeout(this.TimeOut);

				this.TimeOut = setTimeout(() => {
					this.getCalculoDatos();
				}, 1000);

			}else {
				this.bus.$emit('AcValores',this.localService);
			}

        },

		async getCalculoDatos(){
			if (parseFloat(this.localService.IdServicio)>0)
			{
				console.log(this.localService.IdServicio);

				await this.$http.get(
					'servicio/calcularvalores',
					{
						params:{
							IdServicio: this.localService.IdServicio,
							Distancia:  this.localService.Distancia,
							Velocidad:  this.localService.Velocidad
						}
					}
				).then((res) => {

					this.localService.BurdenTotal 	= res.data.data.valores.Burden;
					this.localService.ManoObraT 	= res.data.data.valores.CostoMO;
					this.localService.CostoV 		= res.data.data.valores.CostoVH;

					//console.log(this.localService.BurdenTotal);
					//console.log(this.localService.ManoObraT);
					//console.log(this.localService.CostoV);

					this.bus.$emit('AcValores',this.localService);
				});
			}
		},

		updatedata(){
			this.bus.$emit('AcValores',this.localService, this.localService.Enviar);
		},

		Search()
		{
			if(this.pFiltro !=undefined)
			{


				if(this.FiltroC.Nombre!='')
				{
					clearTimeout(this.TimeOut);

					this.TimeOut = setTimeout(() => {
						this.$emit('FiltrarC');
					}, 900);
				}
				else
				{
					this.$emit('FiltrarC');
				}
			}
		},
    },

	computed: {
		Validate(){
			if(this.pServicios.IdServicio !== undefined){
				this.localService.IdServicio = this.pServicios.IdServicio;
			}
			if(this.pServicios.IdContrato !== undefined){
				this.localService.IdContrato = this.pServicios.IdContrato;
			}

			if(this.pServicios.Client !== undefined){
				this.localService.Client = this.pServicios.Client;
			}
			if(this.pServicios.Sucursal !== undefined){
				this.localService.Sucursal = this.pServicios.Sucursal;
			}

			if(this.pServicios.Direccion !== undefined){
				this.localService.Direccion = this.pServicios.Direccion;
			}
			if(this.pServicios.Distancia !== undefined){
				this.localService.Distancia = this.pServicios.Distancia;
			}
			if(this.pServicios.Velocidad !== undefined){
				this.localService.Velocidad = this.pServicios.Velocidad;
			}
			if(this.pServicios.Para !== undefined){
				this.localService.Para = [];
				this.localService.Para = this.pServicios.Para;
			}

			/*if(this.pServicios.tag !== undefined){
				this.localService.tag = this.pServicios.tag;
			}*/

			if(this.pServicios.Enviar !== undefined){
				this.localService.Enviar = this.pServicios.Enviar;
			}

			if(this.pServicios.BurdenTotal !== undefined){
				this.localService.BurdenTotal = this.pServicios.BurdenTotal;
			}
			if(this.pServicios.ManoObraT !== undefined){
				this.localService.ManoObraT = this.pServicios.ManoObraT;
			}
			if(this.pServicios.CostoV !== undefined){
				this.localService.CostoV = this.pServicios.CostoV;
			}


			if(this.oclientesuc.ContactoS !== undefined){
				this.localClientesuc.ContactoS = this.oclientesuc.ContactoS;
			}
			if(this.oclientesuc.Telefono !== undefined){
				this.localClientesuc.Telefono = this.oclientesuc.Telefono;
			}

			if(this.oclientesuc.Telefono !== undefined){
				this.localClientesuc.Telefono = this.oclientesuc.Telefono;
			}

			if(this.errorvalidacion !== undefined){
				this.localErrorvalidacion = this.errorvalidacion;
			}
			if(this.Consultado !== undefined){
				this.localConsultado = this.Consultado;
			}
			/*
			SE RETIRA EL FUNCIONAMIENTO, YA QUE UTILIZABA V-SHOW PARA OCULTAR CAMPOS MIENTRA SE ESPERABA LA RESPUESTA DEL CLIENTE EN RECOVERY O EN EN NUEVO.
			if(this.Tipo !== undefined){
				this.localTipo = this.Tipo;
			}*/

		}
	}
}
</script>
